
import React, { useEffect } from "react"

import { useAuth } from "react-use-auth"
import Layout from "../components/Layout"

const Auth0CallbackPage = () => {
  const { handleAuthentication } = useAuth()

  useEffect(() => {
    handleAuthentication({ postLoginRoute: "/" })
  }, [])

  return (
    <Layout>
      <span style={{justifyContent: 'center'}}>
          Verifying...
      </span>
    </Layout>
  )
}

export default Auth0CallbackPage